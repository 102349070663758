import { DocumentContent } from './document-content';
import { UserPermissionToPage } from './user-permission-to-page';

export enum PageTeamMembersPermission {
  VIEW = 'view',
  EDIT = 'edit',
  INVITE_ONLY = 'inviteOnly',
}

export enum PagePublicAccessPermission {
  VIEW = 'view',
  EDIT = 'edit',
  INVITE_ONLY = 'inviteOnly',
}

export type Page = {
  id: string;
  content: DocumentContent;
  sequenceNumber: string;
  published: boolean;
  publicAccess: PagePublicAccessPermission;
  pageSlug: string;
  gifImage: number | null;
  gifUpdatedAt: Date | null;
  createdAt: Date;
  updatedAt: Date;
  folderId: string;
  publishedAt: Date | null;
  teamMembersPermission: PageTeamMembersPermission;
  views: number;
  userPermissionToPages: UserPermissionToPage[];
  isViewersCanDuplicateAsTemplate: boolean;
  isBranded: boolean;
  isDistributeBadgeRemoved: boolean;
};

export enum UserPermissionRole {
  EDIT = 'edit',
  VIEW = 'view',
  NONE = 'none',
}

export type GetUserPermissionRoleResponseType = {
  role: UserPermissionRole;
};
